import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { fetchBusData } from './api';
import icon from 'leaflet/dist/images/marker-icon.png';

const busIcon = new L.Icon({
    iconUrl: '/bus-icon.png',
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32]
});

let DefaultIcon = L.icon({
    iconUrl: icon,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
});

L.Marker.prototype.options.icon = DefaultIcon;


function parseDate(dateString){
    const parsedDate = new Date(dateString);

    return parsedDate.toTimeString().replace(/British Summer Time/g, 'BST');
}

function getCurrentLocation() {
    return new Promise((resolve, reject) => {
      if (!navigator.geolocation) {
        resolve({
            latitude:53.3833,
            longitude: -1.8672// Center of Peak District
          });
      } else {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude
            });
          },
          (error) => {
            reject(error);
          }
        );
      }
    });
  }

function Map() {
    const [buses, setBuses] = useState([]);
    const [selectedLine, setSelectedLine] = useState('all');
    const [busLines, setBusLines] = useState(['all']);
    const [position, setPosition] = useState(null);
    useEffect(() => {
        const fetchData = async () => {
            const data = await fetchBusData();
            getCurrentLocation().then((location)=>{
                setPosition([location.latitude, location.longitude]);
                
                console.log(`current location is ${location.latitude},${location.longitude}`);
            })
            
            setBuses(data);
            
            // Extract unique bus lines
            const lines = ['all', ...new Set(data.map(bus => bus.lineRef))];
            setBusLines(lines);
        };
        fetchData();
        // Set up polling every 30 seconds
        const interval = setInterval(fetchData, 30000);
        return () => clearInterval(interval);
    }, []);

    const filteredBuses = selectedLine === 'all' 
        ? buses 
        : buses.filter(bus => bus.lineRef === parseInt(selectedLine));

    return (
        <div>
            <select 
                value={selectedLine} 
                onChange={(e) => setSelectedLine(e.target.value)}
                style={{ marginBottom: '10px' }}
            >
                {busLines.map(line => (
                    <option key={line} value={line}>
                        {line === 'all' ? 'Select Route' : `Route ${line}`}
                    </option>
                ))}
            </select>
            {position && (<MapContainer center={position} zoom={10} style={{ height: '400px', width: '100%' }}>
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                <Marker key='myPosition' position={position}></Marker>
                {filteredBuses.map(bus => (
                    <Marker key={bus.id} position={[bus.latitude, bus.longitude]} icon={busIcon}>
                        <Popup>
                            <div>
                                <h3>Route {bus.lineRef}</h3>
                                <p>From: {bus.originName}</p>
                                <p>To: {bus.destinationName}</p>
                                <p>Updated: {parseDate(bus.lastUpdated)}</p>
                                <p>Bus ID: {bus.vehicleRef}</p>
                            </div>
                        </Popup>
                    </Marker>
                ))}
            </MapContainer>
        )}
        </div>
    );
}

export default Map;