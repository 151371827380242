import { XMLParser } from 'fast-xml-parser';

const API_URL = 'https://bustracker-proxy.sam-barber.workers.dev';

export async function fetchBusData() {
    try {
        console.log('Fetching bus data.');
        const response = await fetch(API_URL);
        const xmlData = await response.text();
        
        const parser = new XMLParser();
        const result = parser.parse(xmlData);

        // Extract VehicleActivity array
        const vehicleActivities = result.Siri.ServiceDelivery.VehicleMonitoringDelivery.VehicleActivity;

        // Map the data without filtering
        return vehicleActivities.map(activity => ({
            id: activity.ItemIdentifier,
            latitude: parseFloat(activity.MonitoredVehicleJourney.VehicleLocation.Latitude),
            longitude: parseFloat(activity.MonitoredVehicleJourney.VehicleLocation.Longitude),
            lineRef: activity.MonitoredVehicleJourney.LineRef,
            vehicleRef: activity.MonitoredVehicleJourney.VehicleRef,
            bearing: parseFloat(activity.MonitoredVehicleJourney.Bearing),
            originName: activity.MonitoredVehicleJourney.OriginName.replace(/_/g, " "),
            destinationName: activity.MonitoredVehicleJourney.DestinationName.replace(/_/g, " "),
            lastUpdated : activity.RecordedAtTime
        }));
    } catch (error) {
        console.error('Error fetching bus data:', error);
        return [];
    }
}